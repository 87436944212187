<template>
  <div class="home">
    <banner :bannerIndex="4" />
    <div class="public_title animate__animated animate__fadeInDown"  v-if="fieldShow">人才招聘</div>
    <div class="content_w hr_one" :class="inwidth>768? 'animate__animated animate__fadeInUp' : '' "  v-if="fieldShow"  :style="{'background-image': 'url('+content.pic+')'}">
      <div class="hr_one_txt">{{content.title}}</div>
      <ul class="hr_one_ul">
        <li v-for="(item,index) in content.gai" :key="index">
          <span v-for="(itema,indexa) in item" :key="indexa">{{itema}}</span>
        </li>
      </ul>
    </div>
    <ul class="content_w hr_two_ul animate__animated animate__fadeInUp" v-if="showTwo">
      <li v-for="(item,index) in list" :key="index" class="hr_two_li" @click="goHrdetail(item.id)">
        <div class="hr_two_txt">
          <span>{{item.title}}</span>
          <span>招聘人数：<b>{{item.num}}</b>人</span>
        </div>
        <div class="hr_two_bt">岗位职责</div>
        <ul class="hrcont_ul">
          <li v-for="(itema,indexa) in item.gai" :key="indexa" class="hrcont_ul_li">{{itema}}</li>
        </ul>
         <div class="modular2_bt ny_more hr_mor">
            <div class="xian"></div>
            <span>查看详情</span>
            <div class="yuan"></div>
            <img src="../../static/img/rjt2.png" class="more_img" />
            <img src="../../static/img/rjt3.png" class="more_img1" />
          </div>
      </li>

    </ul>
    <div class="hr_mor_bt"  v-if="showTwo">
      <div class="modular2_bt ny_more" @click="job">
        <div class="xian"></div>
        <span>查看更多</span>
        <div class="yuan"></div>
        <img src="../../static/img/rjt4.png" class="more_img" />
        <img src="../../static/img/rjt3.png" class="more_img1" />
      </div>
    </div>
     <div v-if="!showTwo" class="height2r"></div>
  </div>
</template>

<script>
import banner from "../components/home/banner.vue";
export default {
  name: "home",
  components: {
    banner
  },
  data() {
    return {
      showTwo:false,
      fieldShow:false,
      inwidth: window.innerWidth,
      content:[],
      list:[],
      pageInfo:{},
      page:0
    };
  },
  mounted(){
     window.addEventListener("scroll", this.onScroll,true);
       if(this.inwidth>768){
          setTimeout((res) => {
          this.fieldShow = true;
          }, 2500);
       }else{
          setTimeout((res) => {
            this.showTwo = true
          }, 1000);
          this.fieldShow = true;
       }
       this.job()
    
  },
  methods:{
    // hrMore(){

    // },
    onScroll(){
				let inHeight = window.innerHeight
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if(scrollTop>0){
        this.fieldShow = true;
      }
      if(this.inwidth >= 1680 && scrollTop > 720){
				  this.showTwo = true
				}else if(this.inwidth >= 1560 && this.inwidth < 1680 && scrollTop > 720){
				  this.showTwo = true
				}else if(this.inwidth >= 1400 && this.inwidth < 1560 && scrollTop > 660){
				  this.showTwo = true
				}else if(this.inwidth >= 1200 && this.inwidth < 1400 && scrollTop > 600){
				  this.showTwo = true
				}else if(this.inwidth >= 1024 && this.inwidth < 1200 && scrollTop > 540){
				  this.showTwo = true
        }
        else if(this.inwidth >= 768 && this.inwidth < 1024 && scrollTop > 440){
				  this.showTwo = true
				}
		},
    job() {
      this.page = Number(this.page) + 1;
      if(this.page > this.pageInfo.pageCount){
          layer.msg('没更更多了~');
          return;
      }
      this.$api.job({
        page:this.page,
      }).then((res) => {
        if (res.status == 1) {
          this.content= res.data.content;
          this.list =this.list.concat(res.data.list);
          this.pageInfo = res.data.pageInfo;
        } else {
          layer.msg(res.msg);
        }
      });
    },
    //从page组件传递过来的当前page
    goHrdetail(id) {
      let that = this;
      this.$router.push({
        path: "/hrDetails?id="+id,
      });
    },
  }
};
</script>
<style scoped>
.hr_one{
  background-repeat:no-repeat;
  background-position: right bottom;
  background-size: 60% auto;
  color: #333333;
  padding-bottom: 2.5rem;
}
.hr_one_txt{
  font-size: .3rem;
}
.hr_one_ul{
  font-size: .16rem;
  width: 5.4rem;
  line-height: .36rem;
}
.hr_one_ul li{
  margin-top: .4rem;
  background: url('../../static/img/hr_bg2.png') no-repeat left 9px;
  background-size: 18px 18px;
  padding-left: .4rem;
}
.hr_one_ul span{
  display: block;
}
.hr_two_ul{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
.hr_two_li{
  width: calc(29% - .8rem);
  font-size: .16rem;
  padding: .3rem .4rem;
  margin-left: .7rem;
  margin-bottom: .7rem;
  box-shadow: rgba(53, 53, 53, 0.1) 0px 0px 20px;
}
.hr_two_li:hover{
  cursor: pointer;
  box-shadow: rgba(53, 53, 53, 0.3) 0px 0px 20px;
}
.hr_two_li:nth-child(3n-2){
  margin-left: 0;
}
.hr_two_txt{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: .2rem;
}

.hr_two_txt span:first-child{
  font-size: .24rem;
  color: #333333;
}
.hr_two_txt span:last-child b{
  color: #a21003;
}
.hr_two_bt{
  width: 1rem;
  height: .35rem;
  line-height: .35rem;
  text-align: center;
  color: #fff;
  background: #a21003;
  margin: .2rem 0;
}
.hrcont_ul{
  line-height: .3rem;
  color: #4a4a4a;
  display: -webkit-box;
  word-break: break-all;
  text-overflow:ellipsis;
  overflow: hidden;
  white-space: pre-line;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:10;
  min-height: 3rem;
}
.hr_two_txt span:first-child{
  display: -webkit-box;
  word-break: break-all;
  text-overflow:ellipsis;
  overflow: hidden;
  white-space: pre-line;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:1;
  flex: 1;
}
.hr_mor{
  font-size: .14rem;
}
.hr_mor_bt{
  display: flex;
  justify-content: center;
  margin-bottom: .8rem;
}
.hr_mor_bt .modular2_bt{
  margin-top: 0;
}
.hr_mor_bt .modular2_bt span{
  color: #333333;
  font-size: .16rem;
}
.hr_mor_bt:hover .xian{
  background: #e60012;
}
.hr_mor_bt .xian{
  background: #333333;
}
@media screen and (max-width:1400px) {
  .hr_two_li{
    width: calc(29% - .4rem);
    margin-left: .4rem;
    padding: .2rem .3rem;
  }
}
@media screen and (max-width:1360px) {
  .hr_two_li{
    width: calc(45% - .8rem);
    margin-left: .4rem;
    padding: .2rem .45rem;
  }
  
  .hr_two_li:nth-child(3n-2){
    margin-left: auto;
  }
  .hr_two_li:nth-child(odd){
    margin-left: 0;
  }
  .hr_two_ul{
    justify-content: space-between;
  }
  
}
@media screen and (max-width:1024px) {
  .hr_two_li{
    margin-bottom: .5rem;
  }
  .hr_one_ul li{
    margin-top: .2rem;
  }
    
}
@media screen and (max-width:768px) {
  .hr_two_ul{
    display: block;
  }
  .hr_two_li{
    width: auto;
  }
  .hr_two_li:nth-child(even){
   margin-left: 0;
  }
  .hr_one{
    background: none;
    padding-bottom: 1rem;
  }
  .hr_one_ul{
    width: auto;
  }
}
@media screen and (max-width:480px) {
.hr_one_ul li{
  font-size: 14px;
  padding-top: .3rem;
  background-size: 10px 10px;
  background-position:0 20px;
  line-height: 24px;
}
.hr_one_txt{
  font-size: 18px;
}
.hr_two_li .hr_two_txt{
  font-size: 12px;
}
.hr_two_li .hr_two_txt span:first-child{
  font-size: 16px;
}

.hr_two_bt{
  font-size: 14px;
  width: 1.5rem;
  height: .45rem;
  line-height: .45rem;
}
.hrcont_ul li{
  font-size: 12px;
  line-height: 24px;
}
.hr_mor{
  font-size: 12px;
}
.hr_mor_bt .modular2_bt span{
  font-size: 14px;
}
.hr_two_li{
  padding: .5rem .45rem;
}
}


</style>